// App.js
import React, { useState, useEffect } from 'react';
import LandingPage from './components/LandingPage';

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    if (mediaQuery.matches) {
      setIsDarkMode(true);
    }
    const handleMediaQueryChange = () => {
      setIsDarkMode(mediaQuery.matches);
    };
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const theme = {
    dark: {
      backgroundColor: '#222',
      color: '#fff',
      minHeight: '100vh',
    },
    light: {
      backgroundColor: '#f0f0f0',
      color: '#333',
      minHeight: '100vh',
    },
  };

  return (
    <div style={isDarkMode ? theme.dark : theme.light}>
      <LandingPage />
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button onClick={toggleTheme}>Toggle Theme</button>
      </div>
    </div>
  );
}

export default App;