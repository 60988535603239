// LandingPage.js
import React, { useState } from 'react';
import { appleProvider, auth, db, googleProvider } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { signInWithPopup } from 'firebase/auth';

const LandingPage = () => {
    const anonymousUserMessage = "By signing up, you'll join our wait list for news and updates about Human Rated AI.";
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userMessage, setUserMessage] = useState(anonymousUserMessage);
    const isLoggedIn = loggedInUser !== null;

    const removeNullValues = (obj) => {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined)
        );
    };

    const storeUserEmail = async (user) => {
        const userRef = doc(db, 'users', user.uid);
        try {
            const commonMessage = "Check back soon for the live app.";
            const email = user?.email || 'E-mail';
            const newUserMessage = `${email} has been added to the wait list! ${commonMessage}`;
            const oldUserMessage = `${email} is already in the wait list! ${commonMessage}`;
            const userSnapshot = await getDoc(userRef);
            await setDoc(doc(db, 'users', user.uid), removeNullValues({
                creationTime: user.metadata.creationTime,
                displayName: user.displayName,
                email: user.email,
                emailVerified: user.emailVerified,
                isAnonymous: user.isAnonymous,
                lastSignInTime: user.metadata.lastSignInTime,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                providerId: user.providerId,
                signupTimestamp: new Date(),
            }));
            setLoggedInUser(user);
            setUserMessage(userSnapshot.exists ? oldUserMessage : newUserMessage);
            console.log('User email stored successfully');
        } catch (error) {
            console.error('Error storing user email:', error);
        }
    };

    const handleAppleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, appleProvider);
            storeUserEmail(result.user);
            console.log("Apple Sign-In successful:", result.user);
        } catch (error) {
            console.error("Apple Sign-In error:", error.message);
        }
    };

    const handleClick = () => {
        const encodedEmail = encodeURIComponent('info@hurated.com');
        window.open(`mailto:${decodeURIComponent(encodedEmail)}`);
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            storeUserEmail(result.user);
            console.log("Google Sign-In successful:", result.user);
        } catch (error) {
            console.error("Google Sign-In error:", error.message);
        }
    };

    const buttons = (
        <>
            <p>Join the community where AI meets human interaction.</p>
            <button onClick={handleGoogleSignIn} style={styles.signUpButton}>
                Sign in with Google
            </button>
            <button onClick={handleAppleSignIn} style={styles.signUpButton}>
                Sign in with Apple
            </button>
        </>
    );

    return (
        <div style={styles.container}>
            {/* Logo */}
            <img src={`${process.env.PUBLIC_URL}/hrai_logo_1024.webp`} alt="Human Rated AI Logo" style={styles.logo} />

            {/* Headline */}
            <h1>Discover, Rate, and Monetize AI Bots</h1>

            {/* Description */}
            <div style={styles.description}>
                Human Rated AI is a platform where you can discover and rate AI agents based on the time humans spend interacting with them.
                Unlike traditional like-based ratings, we value how much time you invest in each bot, giving a more accurate reflection of bot performance.
            </div>

            {/* Tabs Description */}
            <div style={styles.featuresList}>
                <h2>App Features</h2>
                <ul style={styles.tabsList}>
                    <li><strong>AI:</strong> Discover AI bots created by others, sorted by human usage and rating.</li>
                    <li><strong>Create:</strong> Build your own AI bots and share them with the community.</li>
                    <li><strong>Favs:</strong> Keep track of your favorite bots and revisit them easily.</li>
                    <li><strong>Settings:</strong> Manage your account, preferences, and app settings.</li>
                </ul>
            </div>

            {/* Sign-in Buttons */}
            {isLoggedIn ? null : buttons}

            {/* User Message */}
            <p>{userMessage}</p>

            {/* Contact */}
            <p style={styles.contact}>
                Contact: <span style={styles.span} onClick={handleClick} aria-label="Contact Email">info@hurated.com</span>
            </p>
        </div>
    );
};

const styles = {
    contact: {
        marginTop: '50px',
        fontSize: '14px',
        color: '#555',
    },
    container: {
        textAlign: 'center',
        padding: '50px',
        fontFamily: 'Arial, sans-serif',
    },
    description: {
        margin: '20px auto',
        fontSize: '18px',
        lineHeight: '1.6',
        maxWidth: '600px',
    },
    featuresList: {
        margin: '20px auto',
        maxWidth: '600px',
    },
    logo: {
        width: '200px',
        marginBottom: '20px',
    },
    signUpButton: {
        padding: '10px',
        margin: '5px',
        backgroundColor: '#f0f0f0',
        border: 'none',
        cursor: 'pointer',
    },
    span: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    tabsList: {
        listStyleType: 'none',
        padding: '0',
        fontSize: '16px',
        lineHeight: '1.8',
        textAlign: 'left',
    }
};

export default LandingPage;